import { createStore } from 'redux';

import { createError } from './middlewares/errorHandle';
import rootReducer from './rootReducer';
import enhancer from './enhancer';

const initialState = window.__INITIAL_STATE__;

const store = createStore(rootReducer, initialState, enhancer);

export function dispatchError(err: Error, type: string, meta: any) {
  store.dispatch(createError(err, type, meta));
}

if (process.env.NODE_ENV !== 'production') {
  window.__STORE__ = store;
}

export default store;
