class Cache {
  constructor(storage, key) {
    this._storage = storage;
    this._key = key;
  }

  storage(storage) {
    if (storage === undefined) {
      return this._storage;
    }

    this._storage = storage;

    return this;
  }

  key(key) {
    if (key === undefined) {
      return this._key;
    }

    this._key = key;

    return this;
  }

  setStorage(storage) {
    this._storage = storage;
  }

  getItem(key) {
    return this._storage.getItem(key);
  }

  setItem(key, value) {
    return this._storage.setItem(key, value);
  }

  removeItem(key) {
    return this._storage.removeItem(key);
  }

  get() {
    return this.getItem(this._key);
  }

  set(value) {
    return this.setItem(this._key, value);
  }

  remove() {
    return this.removeItem(this._key);
  }
}

export default Cache;
