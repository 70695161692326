import { createReducer, fluxStandardActionHandleEnhancer } from 're-reducer';
import { produce } from 'immer';

import { namespace, initialState } from './selectors';

const reducer = createReducer({
  namespace,
  initialState,
  handleEnhancer: fluxStandardActionHandleEnhancer,
  handles: {
    update(state, action) {
      const {
        payload: { token, user },
      } = action;

      const nextState = produce(state, (draft) => {
        /* eslint-disable no-param-reassign */
        draft.token = token;
        draft.authorized = Boolean(token);
        draft.user = user;
        draft.pending = false;
        /* eslint-enable no-param-reassign */

        return draft;
      });

      return nextState;
    },
  },
});

export default reducer;
