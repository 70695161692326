import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Spin, Icon } from 'antd';

import AppLink from 'components/AppLink';
import styles from './styles.module.css';

/**
 * @param {Record<string, any>} props
 */
function Loading(props) {
  const {
    errorText = '加载过程中出现错误，请稍后再试',
    timedOutText = '加载超时，请稍后再试',
    error,
    timedOut,
    pastDelay,
    retry,
  } = props;

  const handleRetry = useCallback(() => {
    if (timedOut) {
      retry();
    }

    if (error) {
      window.location.reload();
    }
  }, [timedOut, error, retry]);

  if (error || timedOut) {
    return (
      <div className={styles.message}>
        <div>{error ? errorText : timedOutText}</div>
        <div>
          <AppLink onClick={retry && handleRetry}>立即重试</AppLink>
        </div>
      </div>
    );
  }

  if (pastDelay) {
    const indicator = <Icon className={styles.indicator} type="loading" />;

    return <Spin indicator={indicator} />;
  }

  return null;
}

if (process.env.NODE_ENV !== 'production') {
  /** @type {any} */
  Loading.propTypes = {
    errorText: PropTypes.node,
    timedOutText: PropTypes.node,
    // eslint-disable-next-line react/forbid-prop-types
    error: PropTypes.object,
    timedOut: PropTypes.bool,
    pastDelay: PropTypes.bool,
    retry: PropTypes.func,
  };
}

export default Loading;
