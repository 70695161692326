import tokenCache from 'services/cache/token';

export const namespace = 'profile';
export const initialState = {
  pending: true,
  error: null,
  token: tokenCache.get(),
  user: null,
  authorized: false,
};

export function pendingSelector(state) {
  return state[namespace].pending;
}

export function errorSelector(state) {
  return state[namespace].error;
}

export function tokenSelector(state) {
  return state[namespace].token;
}

export function userSelector(state) {
  return state[namespace].user;
}

export function authorizedSelector(state) {
  return state[namespace].authorized;
}
