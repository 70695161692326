function jsonCache(Cache) {
  class JSONCache extends Cache {
    getItem(key) {
      return JSON.parse(super.getItem(key));
    }

    setItem(key, value) {
      return super.setItem(key, JSON.stringify(value));
    }
  }

  return JSONCache;
}

export default jsonCache;
