import 'react-app-polyfill/stable';
import 'moment/locale/zh-cn';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import 'styles.css';
import App from 'scenes/App';
import { unregister } from 'serviceWorker';

moment.locale('zh-cn');
ReactDOM.render(<App />, document.getElementById('root'));
unregister();
